import Image from '@jetshop/ui/Image';
import { transparentDataImg } from '@jetshop/ui/Image/Image';
import ProductLink from '@jetshop/ui/ProductLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import React from 'react';
import { styled } from 'linaria/react';
import { GridPrice } from '../Price';
import t from '@jetshop/intl';
import { Favourite } from '../ProductList/Favourite';
import { theme } from '../Theming/Theming';
import useCustomFields from '../ui/hooks/useCustomFields';
import { ParsedHistoricalPrice } from '../ProductPage/Price/ParsedHistoricalPrice';

export const ProductCardInner = styled('div')`
    padding: ${theme.productGrid.product.padding.sm};
    background-color: ${theme.productGrid.product.background};
    border: ${theme.productGrid.product.border};
    position: relative;
    line-height: 1;
    min-width: 0;

    ${theme.above.md} {
        padding: ${theme.productGrid.product.padding.md};
    }

    ${theme.above.xl} {
        padding: ${theme.productGrid.product.padding.lg};
    }

    > a {
        color: inherit;
        display: block;
        position: relative;
    }

    > button {
        width: 100%;
    }

    text-align: center;

    [data-flight-price] {
        justify-content: center;
    }
`;

export const Details = styled('div')`
    margin: 1rem 0 0.5rem;
    position: relative;
`;

export const DetailsInner = styled('div')`
    position: relative;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-grow: 1;
    align-self: stretch;
    align-items: flex-end;

    > * {
        flex-grow: 0;
        width: 100%;
    }
`;

const ImageWrapper = styled.div`
    position: relative;
`;

export const BadgeWrapper = styled('div')`
    > div > div {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        > div {
            &.red,
            &.black,
            &.yellow {
                height: 100%;

                img {
                    display: none;
                }

                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
                font-weight: bold;
                position: relative;
                font-size: 1rem;
                padding: 7px 10px 5px;
                margin-top: 7px;
                margin-right: 5px;

                &:first-child {
                    margin-left: 0;
                }

                span {
                    position: static;
                }
            }

            &.red {
                background: ${theme.colors.primary};
                color: ${theme.colors.white};
            }

            &.black {
                background: ${theme.colors.black};
                color: ${theme.colors.white};
            }

            &.yellow {
                background: ${theme.colors.yellow};
                color: ${theme.colors.font};
            }
        }
    }

    img {
        width: auto;
        height: auto;
        max-width: 70px;

        ${theme.below.md} {
            max-width: 50px;
        }
    }
`;

export const Name = styled('h4')`
    margin: 0 0 0.7rem 0 !important;
    padding: 0;
    align-self: stretch;
    flex-grow: 1;
    line-height: 1.5;
    font-family: ${theme.productGrid.product.name.fontFamily} !important;
    font-weight: ${theme.productGrid.product.name.fontWeight} !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-transform: uppercase;
`;
export const SubName = styled('h5')`
    margin: 0 0 0 0;
    align-self: stretch;
    flex-grow: 1;
    line-height: 1.2;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-transform: uppercase;
    font-weight: ${theme.weights.normal};

    ${theme.below.md} {
        font-size: 0.9rem;
    }
`;
export const ListPrice = styled(GridPrice)``;

const ProductCardComponent = ({
                                product,
                                imageAspect,
                                imageSizes,
                                children,
                                showFavorite,
                                loadImageEagerly,
                                categoryPath,
                                ...rest
                              }) => {
  //HAS IMAGES
  const hasImages = product.images && product.images.length > 0;

  const customFields = useCustomFields(product);

  //STANDARD PRODUCT IMAGE
  const images = product?.images;

  let badges = [...product.badges];

  product.isPackage &&
  badges.push({
    location: 'TOP_RIGHT',
    name: 'Package',
    text: t('Package'),
    style: 'package-badge'
  });

  return (
    <ProductCardInner className="product-card-inner">
      <ProductLink product={product} categoryPath={categoryPath} {...rest}>
        {hasImages ? (
          <ImageWrapper>
            <Image
              sizes={imageSizes}
              aspect={imageAspect}
              alt={images[0].alt}
              src={images[0].url}
              modifiedDate={images[0].modifiedDate}
              critical={loadImageEagerly}
            />
            <BadgeWrapper>
              <Badges badges={product.badges} />
            </BadgeWrapper>
          </ImageWrapper>
        ) : (
          <Image src={transparentDataImg} aspect={imageAspect} />
        )}
        {showFavorite && (
          <Favourite
            product={product}
            style={{
              position: 'absolute'
            }}
          />
        )}
      </ProductLink>
      <Details className="product-card-detail">
        <DetailsInner>
          <header>
            <SubName className="sub-name">
              {customFields?.varumarke?.value}
            </SubName>
            <ProductLink product={product} categoryPath={categoryPath}>
              <Name>{product.name}</Name>
            </ProductLink>
          </header>
          <ParsedHistoricalPrice
            price={product.price}
            previousPrice={product.previousPrice}
            hidePrice={product.hidePrice}
            history={product.history}
            showToolTip={false}
            showRecPrice={false}>
            <ListPrice />
          </ParsedHistoricalPrice>
        </DetailsInner>
      </Details>
      {children}
    </ProductCardInner>
  );
};

export function ProductCard({
                              product,
                              className,
                              imageAspect = theme.productGrid.product.imageAspect,
                              imageSizes = [1 / 2, 1 / 2, 1 / 3, 1 / 4, 1 / 5],
                              forwardRef,
                              as = 'div',
                              children,
                              showFavorite = true,
                              showVariants = true,
                              loadImageEagerly = false,
                              ...linkProps
                            }) {
  const Tag = as;

  //PROPS TO PASS TO PRODUCTCARD COMP
  const props = {
    imageAspect,
    imageSizes,
    children,
    showFavorite,
    loadImageEagerly,
    ...linkProps
  };
  return (
    <Tag
      className={`product-card ${className}`}
      data-testid="product"
      data-product-card
      ref={forwardRef}
    >
      <ProductCardComponent product={product} {...props} />
    </Tag>
  );
}
