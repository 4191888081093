import React from 'react';
import t from '@jetshop/intl';
import Image, { transparentDataImg } from '@jetshop/ui/Image/Image';
import { Price } from '@jetshop/ui/Price';
import ProductLink from '@jetshop/ui/ProductLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import { css, cx } from 'linaria';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { ButtonNoAction } from './Button';
import useCustomFields from './hooks/useCustomFields';
import { Favourite } from '../ProductList/Favourite';
import { ParsedHistoricalPrice } from '../ProductPage/Price/ParsedHistoricalPrice';

const wrapperStyling = css`
    font-size: 1rem;
    position: relative;
    border-radius: 1rem;
    background-color: ${theme.colors.white};
    box-sizing: border-box;

    height: calc(100% - 0.25rem);

    ${theme.above.md} {
        :hover {
            z-index: 100;
            filter: drop-shadow(0px 0px 15px rgba(46, 46, 46, 0.1));
        }
    }

    ${theme.below.md} {
        border: 1px solid ${theme.colors.sumSecondaryLightGray};
        margin-left: 0.25rem;
        margin-bottom: 0.25rem;
    }

    > a {
        text-decoration: none;
        color: inherit;
        display: block;
    }

    .product-card-detail {
        text-align: center;

        ${theme.above.md} {
            padding: 1rem 1rem 2.5rem 1rem;
        }

        ${theme.below.md} {
            padding: 1rem 0.5rem 2.5rem 0.5rem;
        }
    }

    .package-badge {
        background: black;
        color: white;
        font-size: 14px;
        padding: 3px 6px;
    }

    .badge-top-right {
        top: 3em;
    }
`;

const ImageWrapper = styled.div`
    padding: 1rem;
    padding-bottom: 0rem;
`;

export const BrandName = styled.h3`
    height: 1.5rem; /* One rows of text */
    overflow: hidden;

    ${theme.fontStyles.product.brand}
    text-transform: uppercase;
    font-weight: normal;
`;

export const ProductName = styled.h3`
    height: 3rem; /* Two rows of text */
    overflow: hidden;

    ${theme.fontStyles.product.name}
    text-transform: uppercase;
    margin-top: 0.5rem;
`;

export const ProductType = styled.h4`
    ${theme.fontStyles.product.type}
    color: ${theme.colors.darkerGrey};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const PriceWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
`;

const PriceStyled = styled(Price)`
    ${theme.fontStyles.product.price}
    .new-price {
        color: #eb0000;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .old-price {
        color: ${theme.colors.darkerGrey};
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 1rem;
    }
`;

const FavouriteStyled = styled(Favourite)`
    position: absolute;
    top: 0rem;
    right: 0rem;
    z-index: 10;

    button {
        background: transparent;
        padding: 1rem;

        svg.active path {
            fill: red;
            stroke: red;
        }
    }

,
`;

const BuyButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
`;

export function ProductCard({
                              product,
                              className,
                              imageAspect = '1:1',
                              imageSizes = [1 / 4, 1 / 3, 1 / 2, 1 / 2, 1 / 2],
                              forwardRef,
                              as = 'li',
                              children,
                              showBuyButton = true,
                              showFavoriteButton = true,
                              categoryPath,
                              ...linkProps
                            }) {
  const customFields = useCustomFields(product);

  const hasImages = product.images && product.images.length > 0;
  const Tag = as;
  let badges = [...product.badges];
  product.isPackage &&
  badges.push({
    location: 'TOP_RIGHT',
    name: 'Package',
    text: t('Package'),
    style: 'package-badge'
  });

  return (
    <Tag
      className={cx('product-card', wrapperStyling, className)}
      data-testid="product"
      data-product-card
      ref={forwardRef}
    >
      <ProductLink product={product} categoryPath={categoryPath} {...linkProps}>
        {showFavoriteButton && <FavouriteStyled product={product} />}
        <ImageWrapper>
          {hasImages ? (
            <Image
              sizes={imageSizes}
              aspect="1:1"
              alt={product.images[0].alt}
              src={product.images[0].url}
            >
              <Badges badges={badges} />
            </Image>
          ) : (
            <Image src={transparentDataImg} />
          )}
        </ImageWrapper>
        <section className="product-card-detail">
          <header>
            {customFields.varumarke && customFields.varumarke.value ? (
              <BrandName>{customFields.varumarke.value}</BrandName>
            ) : null}
            <ProductName>{product.name}</ProductName>
            {<ProductType>{product.subName}</ProductType> || null}
          </header>
          <PriceWrapper>
            <ParsedHistoricalPrice
              price={product.price}
              previousPrice={product.previousPrice}
              hidePrice={product.hidePrice}
              history={product.history}
              showToolTip={false}
              showRecPrice={false}>
              <PriceStyled
                price={product.price}
                previousPrice={product.previousPrice}
              />
            </ParsedHistoricalPrice>
          </PriceWrapper>
          {showBuyButton && (
            <BuyButtonWrapper>
              <ButtonNoAction>{t('Buy')}</ButtonNoAction>
            </BuyButtonWrapper>
          )}
        </section>
      </ProductLink>
      {children}
    </Tag>
  );
}
