import React, { useEffect } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Price } from '@jetshop/ui/Price';



const extractLowestHistoricalPrice = (priceHistory) => {
  let minPriceObj = null;

  if (priceHistory?.length > 1) {
    priceHistory = priceHistory.slice(0, priceHistory.length - 1);
  }

  priceHistory.forEach((priceObj) => {
    if (!minPriceObj || priceObj.price.incVat < minPriceObj?.incVat) {
      minPriceObj = priceObj.price;
    }
  });

  return minPriceObj;
};

const ToolTipWrapper = styled('div')`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ToolTipButton = styled('button')`
    font-size: 1.5rem;
    background-color: transparent;
    color: ${theme.colors.sumPrimaryRed};
    min-height: 1.5rem;
    min-width: 1.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0.5rem;
    left: 0;
    border: 2px solid transparent;

    svg {
        width: 1rem;
        height: 1rem;
    }

    &:hover {
        border: 2px solid ${theme.colors.grey};

        + div {
            display: block;
        }
    }

    &.open {
        border: 2px solid ${theme.colors.sumPrimaryRed};
    }

`;

const ToolTipText = styled('div')`
    position: absolute;
    bottom: 2.125rem;
    left: -2rem;
    transform: translateX(-50%);
    background: #333;
    color: #fff;
    border: 1px solid #ccc;
    width: 300px;
    display: none;
    padding: 1rem;
    z-index: 2;
    font-size: 0.9375rem;
    line-height: 1.3;
    box-shadow: 0 0 5px 0 #ffffff66;
    
    ${theme.below.md} {
        width: 190px;        
    }
    
    &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        border: solid;
        border-color: #333 transparent transparent;
        border-width: 8px;
        
    }
    
    &.open {
        display: block;
    }

`;

const RecPrice = styled('div')`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 0 0.5rem;
    
    * {
        font-size: 0.9375rem !important;
        color: ${theme.colors.darkgrey} !important;
    }
`;


const PriceHistoryToolTip = () => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    const closeToolTip = (event) => {
      if (!event.target.closest('#price-tooltip-wrapper') && open) {
        setOpen(false);
      }
    };

    document.addEventListener('click', closeToolTip);

    return () => {
      document.removeEventListener('click', closeToolTip);
    };
  }, [open]);

 const checkIfClosingOnEsc = event => {
    if (event.key === 'Escape' && open) {
      setOpen(false);
    }
  };

  return (
    <ToolTipWrapper
      id='price-tooltip-wrapper'
      onKeyDown={checkIfClosingOnEsc}>
      <ToolTipButton
        onClick={() => setOpen(!open)}
        aria-label="Förklaring av nedsatt pris"
        aria-controls="price-tooltip-text"
        aria-expanded={open}
        className={open ? 'open' : ''}
      >
        <FontAwesomeIcon icon={faInfoCircle} />
      </ToolTipButton>
      <ToolTipText
        id="price-tooltip-text"
        className={open ? 'open' : ''}
      >
        Lägsta pris 30 dagar innan senaste prisändringen.
      </ToolTipText>
    </ToolTipWrapper>
  );
};


export function ParsedHistoricalPrice(
  {
    history,
    price,
    previousPrice,
    children,
    showToolTip = false,
    showRecPrice = false,
    ...rest
  }) {


  const minPriceObj = history?.previousPrice?.length > 1 ? extractLowestHistoricalPrice([...history.previousPrice]) : null;
  const hasDiscount = price?.incVat < previousPrice?.incVat;
  const hasTrueDiscount = price?.incVat < minPriceObj?.incVat;
  const newPreviousPrice = hasDiscount && hasTrueDiscount ? minPriceObj : price;
  const hasModifiedPreviousPrice = previousPrice?.incVat !== newPreviousPrice?.incVat;

  const childrenWithProps = React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      price: price,
      previousPrice: newPreviousPrice,
      ...rest
    });
  });

  return (
    <>
      {childrenWithProps}
      {hasDiscount && hasTrueDiscount && showToolTip && <PriceHistoryToolTip />}
      {hasDiscount && hasModifiedPreviousPrice && showRecPrice && (
        <RecPrice><span>Rek. utpris:&nbsp;</span><Price price={previousPrice}></Price></RecPrice>
      )}
    </>
  );

}
